<template>
  <b-form class="form" @submit.stop.prevent="onSubmit">
    <div role="alert" class="alert alert-dark">
      <div class="alert-text">
        {{ $t("TWO_FACTOR") }}
      </div>
    </div>

    <div
      role="alert"
      v-bind:class="{ show: errors.length }"
      class="alert fade alert-danger"
    >
      <div class="alert-text" v-for="(error, i) in errors" :key="i">
        {{ Array.isArray(error) ? error[0] : error }}
      </div>
    </div>

    <b-form-group id="code-input-group" label="" label-for="code-input">
      <v-otp-input
        length="4"
        name="code"
        @finish="onSubmit"
        v-model="$v.form.code.$model"
      ></v-otp-input>

      <b-form-invalid-feedback id="code-input-live-feedback">
        {{ $t("LENGTH", { name: "Code", length: "4" }) }}
      </b-form-invalid-feedback>
      <b-form-valid-feedback>
        {{ $t("VALID") }}
      </b-form-valid-feedback>
    </b-form-group>

    <!--begin::Action-->
    <div
      class="form-group d-flex flex-wrap justify-content-between align-items-center"
    >
      <span class="btn btn-text" @click="$emit('click:back')">
        {{ $t("BACK_BUTTON") }}
      </span>
      <button
        ref="kt_login_signin_submit"
        class="btn btn-primary font-weight-bold px-9 py-4 my-3 font-size-3"
      >
        {{ $t("BUTTON") }}
      </button>
    </div>
    <!--end::Action-->
  </b-form>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { mapGetters, mapState } from "vuex";
import { LOGIN_TWO_FACTOR } from "@/core/services/store/auth.module";

import { validationMixin } from "vuelidate";
import { minLength, maxLength, required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  props: {
    email: String,
    password: String
  },
  name: "TwoFactorForm",
  data() {
    return {
      form: {
        code: ""
      }
    };
  },
  validations: {
    form: {
      code: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(4)
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        code: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const code = this.$v.form.code.$model;

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // send login request
      this.$store
        .dispatch(LOGIN_TWO_FACTOR, {
          email: this.email,
          password: this.password,
          code
        })
        // go to which page after successfully login
        .then(() => {
          this.$router.push({ name: "dashboard" });
        })
        .finally(() => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        });
    }
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
    ...mapState({
      errors: state => state.auth.errors
    })
  }
};
</script>
